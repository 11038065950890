<template>
  <div class="mobile-home">
    <div class="mobile-home-header">
      <img src="../../../assets/mobile_images/mo_company_bg.png" alt="" />
      <!-- <div class="mobile-header-btn">进一步了解</div> -->
    </div>
    <!-- <mobileTab :tabs="tabs" @tabChange="onTabChange" ></mobileTab> -->
    <!-- 传世简介 -->
    <mobileTitle titleZh="传世简介" marginTopZh=".78rem" marginBottomZh=".48rem"></mobileTitle>
    <img class="w100f" src="../../../assets/mobile_images/mo_company_intro.png" alt="" />
    <div class="mobile-intro-box">
      <p class="company-intro-txt">
        北京传世博润科技有限公司（原名：深圳传世般若科技有限公司）成立于2015年7月，国内领先的医药流通数字化解决方案服务商。公司面向医药企业和医院，围绕医药供应链的作业场景，自主研发了集SaaS云平台、智能硬件和大数据产品于一体的数字化解决方案；同时通过数字化解决方案整合搭建了国内最大流通服务资源平台，以数据驱动服务资源，为药企和医院实现精准营销和物流优化。作为国家健康医疗大数据工程参建单位，传世科技已经成为医药行业流通数字化解决方案的领军企业。传世科技目前在深圳、北京、武汉、威海、成都、天津均成立子公司，为公司集团化发展奠定了坚实的基础。
      </p>
      
      <div class="intro-num-box">
        <div class="intro-num-list">
          <div class="intro-num-title">40+</div>
          <p>软件著作</p>
        </div>
        <div class="intro-num-list">
          <div class="intro-num-title">20+</div>
          <p>各项专利</p>
        </div>
        <div class="intro-num-list">
          <div class="intro-num-title">5万+</div>
          <p>合作终端</p>
        </div>
      </div>
    </div>
    <!-- 知识产权 -->
    <mobileTitle titleZh="知识产权" marginTopZh=".78rem" marginBottomZh=".48rem"></mobileTitle>
    <div class="mobile-zscq-box">
      <div class="zscq-list" v-for="(item, index) in zscqList" :key="index" @click="getImg(zscqList,index)">
        <img :src="item" alt="" />
      </div>
    </div>
    <!-- 安全守护 -->
    <mobileTitle titleZh="安全守护" marginTopZh=".78rem" marginBottomZh=".48rem"></mobileTitle>
    <mobileImg :url="safeImg" boxHeight="3.4rem" marginBox="0 .4rem"></mobileImg>
    <!-- 公司照片 -->
    <mobileTitle titleZh="公司照片" marginTopZh=".78rem" marginBottomZh=".48rem"></mobileTitle>
    <img class="w100f" @click="getImg(companyImg,0)" src="../../../assets/images/company_01.png" alt="" />
    <!-- 企业文化 -->
    <mobileTitle titleZh="企业文化" marginTopZh=".78rem" marginBottomZh=".48rem"></mobileTitle>
    <img class="w90f" src="../../../assets/mobile_images/mo_company_culture.png" alt="" />
    <div class="mobile-icon-box">
      <img src="../../../assets/mobile_images/mo_company_icon.png" alt="" />
    </div>
    <!-- 发展历程 -->
    <mobileTitle titleZh="发展历程" marginTopZh=".78rem" marginBottomZh=".48rem"></mobileTitle>
    <mobileImg :url="fzlcImg" boxHeight="4rem" marginBox="0 .4rem" paddingBox=".4rem 0"></mobileImg>
    <!-- 公司照片 -->
    <mobileTitle titleZh="公司荣誉" marginTopZh="1.4rem" marginBottomZh=".62rem"></mobileTitle>
    <img class="w100f" @click="getImg(honorImg,0)" src="../../../assets/mobile_images/mo_company_honor.png" alt="" />
    <!-- 人才召集 -->
    <!-- <mobileTitle titleZh="人才召集" marginTopZh=".78rem" marginBottomZh=".48rem"></mobileTitle>
    <el-table :data="tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)" style="width: 100%">
      <el-table-column  prop="name" label="职位名称"></el-table-column>
      <el-table-column width="100" prop="type" label="职位类型"></el-table-column>
      <el-table-column width="100" prop="address" label="工作地点"></el-table-column>
      <el-table-column width="120" prop="date" label="更新时间"></el-table-column>
      <el-table-column width="150" prop="tel" label="联系方式"></el-table-column>
    </el-table>
    <div class="h50"></div>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page.sync="pageIndex"
      :page-size="pageSize"
      layout="total, prev, pager, next"
      :total="tableData.length"
    >
    </el-pagination> -->
  </div>
</template>
<script>
import mobileTitle from '@/components/title/mobile_title.vue'; //标题组件
import mobileTab from '@/components/tab/mobile_tab.vue'; //选项卡组件
import mobileImg from '@/components/img/mobile_img.vue'; //图片组件
export default {
  components: {
    mobileTitle,
    mobileTab,
    mobileImg,
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      safeImg: require('../../../assets/mobile_images/mo_company_safe.png'),
      fzlcImg: require('../../../assets/images/company_fzlc.png'),
      companyImg:[require('../../../assets/images/company_01.png')],
      honorImg:[require('../../../assets/mobile_images/mo_company_honor.png')],
      zscqList: [
        require('../../../assets/images/company_zscq1.jpg'),
        require('../../../assets/images/company_zscq2.jpg'),
        require('../../../assets/images/company_zscq3.jpg'),
        require('../../../assets/images/company_zscq4.jpg'),
        require('../../../assets/images/company_zscq5.jpg'),
        require('../../../assets/images/company_zscq6.jpg'),
        require('../../../assets/images/company_zscq7.jpg'),
        require('../../../assets/images/company_zscq8.jpg'),
        require('../../../assets/images/company_zscq9.jpg'),
      ],
      tableData: [
        {
          name: '自主研发SAAS前端开发',
          type: '技术',
          date: '2022-10-20',
          address: '武汉',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: 'Java开发工程师',
          type: '技术',
          date: '2022-10-20',
          address: '武汉/威海   ',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '医药售前讲师',
          type: '技术',
          date: '2022-10-20',
          address: '武汉',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '售前物流规划',
          type: '技术',
          date: '2022-10-20',
          address: '威海',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '电器工程师',
          type: '产品',
          date: '2022-10-20',
          address: '威海',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '项目实施',
          type: '技术',
          date: '2022-10-20',
          address: '威海',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '运营专员',
          type: '技术',
          date: '2022-10-20',
          address: '武汉',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '营销部商务助理',
          type: '技术',
          date: '2022-10-20',
          address: '武汉',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '销售经理/总监',
          type: '技术',
          date: '2022-10-20',
          address: '武汉',
          tel: 'xieyaling@csbr.cn',
        },
      ],
    };
  },
  methods: {
    onTabChange(item, index) {
      this.currentIndex = index;
      // this.$refs.tabs.currentIndex = index
      console.log(index);
    },
    getImg(imgList,index) {
      this.$ImagePreview({
        images: imgList,
        startPosition: index,
      });
    },
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
    },
  },
};
</script>
<style lang="less" scoped>
.mobile-home {
  margin: 0 auto;
  .mobile-home-header {
    position: relative;
    min-height: 2.6rem;
    img {
      width: 100%;
    }
    .mobile-header-btn {
      position: absolute;
      left: 50%;
      bottom: 1.8rem;
      transform: translateX(-50%);
      width: 2.4rem;
      height: 0.72rem;
      line-height: 0.72rem;
      font-size: 0.28rem;
      text-align: center;
      color: #fff;
      background: #4fa1a4;
      border-radius: 0.04rem;
    }
  }
  //介绍
  .mobile-intro-box {
    padding: 0.64rem 0.71rem;
    box-sizing: border-box;
    .company-intro-txt {
      font-size: 0.28rem;
      line-height: 0.5rem;
      text-indent: 2em;
      color: #3d3d3d;
      text-align: left;
    }
    .intro-num-box {
      display: flex;
      justify-content: space-between;
      padding: 0.42rem 0.14rem;
      box-sizing: border-box;
      .intro-num-list {
        .intro-num-title {
          font-size: 0.8rem;
          color: #4fa1a4;
          line-height: 1.32rem;
        }
        p {
          font-size: 0.32rem;
          color: #3d3d3d;
          text-align: left;
        }
      }
    }
  }
  //知识产权
  .mobile-zscq-box {
    display: flex;
    flex-wrap: wrap;
    padding: 0 0.4rem;
    box-sizing: border-box;
    .zscq-list {
      width: 49%;
      padding: 0.4rem;
      background: #f5f5f5;
      box-sizing: border-box;
      margin-bottom: 0.16rem;
      &:nth-child(2n + 1) {
        margin-right: 2%;
      }
      img {
        width: 100%;
      }
    }
  }
  //icon
  .mobile-icon-box {
    margin: 0.78rem 0;
    padding: 0.48rem 0.4rem;
    background: #f3f3f3;
    box-sizing: border-box;
    img {
      width: 100%;
    }
  }
}
</style>
