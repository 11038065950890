<template>
  <div class="home-block">
    <div class="home">
      <!-- 头部开始 -->
      <div class="home-header">
        <img class="w100f" src="../../assets/images/company_bg.png" alt="" />
        <div class="bg-text">
          <div class="bg-text-left">
            <p class="font-title">走进传世</p>
          </div>
        </div>
      </div>
      <!-- 头部结束 -->
      <!-- 中间内容开始 -->
      <div class="main-contain">
        <div class="h120"></div>
        <!-- 公司介绍 -->
        <div class="company-intro">
          <img src="../../assets/images/company_intro.png" alt="" />
          <div class="company-intro-box">
            <div class="company-title">传世简介</div>
            <p class="company-intro-txt">
              深圳传世般若科技有限公司（以下简称“传世科技”）创立于2015年8月，国家高新技术企业，专注于以供应链解决方案，构筑医药产业互联网，向医药企业及医院提供营销及供应链数字化的综合服务。
            </p>
            <p class="company-intro-txt">
              传世科技现已拥有医药供应链领域软件著作权40余项，各项专利20余项，为不同客户类型提供个性化产品服务，形成了完整的数字化供应链服务解决方案和产品，推动数字化医药流通变革。
            </p>
            <div class="intro-num-box">
              <div class="intro-num-list">
                <div class="intro-num-title">40+</div>
                <p>软件著作</p>
              </div>
              <div class="intro-num-list">
                <div class="intro-num-title">20+</div>
                <p>各项专利</p>
              </div>
              <div class="intro-num-list">
                <div class="intro-num-title">5万+</div>
                <p>合作终端</p>
              </div>
            </div>
          </div>
        </div>
        <!-- 知识产权 -->
        <Title titleZh="知识产权" titleEn="" marginBottomZh="48px"></Title>
        <div class="zscq-swiper">
          <swiper :options="swiperOption1">
            <!-- 轮播的图片 -->
            <swiper-slide v-for="(item, index) in swiperList1" :key="index"><img :src="item.url"/></swiper-slide>
          </swiper>
          <!-- 左右按钮 -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <!-- 安全守护 -->
        <Title titleZh="安全守护" titleEn="" marginBottomZh="48px" marginTopZh="68px"></Title>
        <div class="aqsh-box">
          <div class="aqsh-box-list">
            <img src="../../assets/images/company_aqsh1.jpg" alt="" />
          </div>
          <div class="aqsh-box-list">
            <img src="../../assets/images/company_aqsh2.jpg" alt="" />
          </div>
          <div class="aqsh-box-list">
            <img src="../../assets/images/company_aqsh3.jpg" alt="" />
          </div>
        </div>
        <!-- 公司照片 -->
        <Title titleZh="公司照片" titleEn="" marginBottomZh="48px"></Title>
        <div class="pic-swiper">
          <swiper :options="swiperOption2">
            <!-- 轮播的图片 -->
            <swiper-slide v-for="(item, index) in swiperList2" :key="index"> <img :src="item.url"/></swiper-slide>
          </swiper>
          <!-- 左右按钮 -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <!-- 企业文化 -->
        <Title titleZh="企业文化" titleEn="" marginBottomZh="48px"></Title>
        <div class="culture-box">
          <img src="../../assets/images/company_gswh1.jpg" alt="" />
          <img src="../../assets/images/company_gswh2.jpg" alt="" />
          <img src="../../assets/images/company_gswh3.png" alt="" />
          <img src="../../assets/images/company_gswh4.png" alt="" />
        </div>
        <div class="h190"></div>
      </div>
      <img class="w100f" src="../../assets/images/company_logo.png" alt="" />
      <div class="main-contain">
        <!-- 发展历程 -->
        <Title titleZh="发展历程" titleEn="" marginBottomZh="48px"></Title>
        <img class="w100f" src="../../assets/images/company_fzlc.png" alt="" />
        <!-- 公司荣誉 -->
        <Title titleZh="公司荣誉" titleEn="" marginBottomZh="48px"></Title>
        <div class="pic-swiper">
          <swiper :options="swiperOption2">
            <!-- 轮播的图片 -->
            <swiper-slide v-for="(item, index) in swiperList3" :key="index"><img :src="item.url"/></swiper-slide>
          </swiper>
          <!-- 左右按钮 -->
          <div class="swiper-button-prev"></div>
          <div class="swiper-button-next"></div>
        </div>
        <!-- 人才召集 -->
        <Title titleZh="人才召集" titleEn="" marginBottomZh="48px"></Title>
        <el-table :data="tableData.slice((pageIndex - 1) * pageSize, pageIndex * pageSize)" style="width: 100%" height="480">
          <el-table-column prop="name" label="职位名称"></el-table-column>
          <!-- <el-table-column prop="type" label="职位类型"></el-table-column> -->
          <el-table-column prop="address" label="工作地点"></el-table-column>
          <el-table-column prop="date" label="更新时间"></el-table-column>
          <el-table-column prop="tel" label="联系方式"></el-table-column>
        </el-table>
        <div class="h50"></div>
        <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page.sync="pageIndex"
          :page-size="pageSize"
          layout="total, prev, pager, next"
          :total="tableData.length"
        >
        </el-pagination>
        <div class="h150"></div>
      </div>
    </div>
    <mobilePage></mobilePage>
  </div>
</template>

<script>
import mobilePage from '@/views/company/mobile/company_cs.vue'//移动端页面
import { swiper, swiperSlide } from 'vue-awesome-swiper';
import 'swiper/dist/css/swiper.css';
import Tab from '@/components/tab/tab.vue'; //选项卡组件
import Title from '@/components/title/title.vue'; //标题组件
export default {
  name: 'home',
  components: {
    swiper,
    swiperSlide,
    Tab,
    Title,
    mobilePage
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 10,
      swiperOption1: {
        loop: true, // 设置图片循环
        autoplay: {
          delay: 2000, 
          disableOnInteraction: false,
        }, 
        spaceBetween: 15, //控制间隔
        slidesPerView: 4, //控制展示个数
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
      },
      swiperOption2: {
        loop: true, 
        autoplay: {
          delay: 3000, 
          disableOnInteraction: false,
        }, 
        navigation: {
          prevEl: '.swiper-button-prev',
          nextEl: '.swiper-button-next',
        },
      },
      swiperList1: [
        {
          url: require('../../assets/images/company_zscq1.jpg'),
        },
        {
          url: require('../../assets/images/company_zscq2.jpg'),
        },
        {
          url: require('../../assets/images/company_zscq3.jpg'),
        },
        {
          url: require('../../assets/images/company_zscq4.jpg'),
        },
        {
          url: require('../../assets/images/company_zscq5.jpg'),
        },
        {
          url: require('../../assets/images/company_zscq6.jpg'),
        },
        {
          url: require('../../assets/images/company_zscq7.jpg'),
        },
        {
          url: require('../../assets/images/company_zscq8.jpg'),
        },
        {
          url: require('../../assets/images/company_zscq9.jpg'),
        },
      ],
      swiperList2: [
        {
          url: require('../../assets/images/company_01.png'),
        },
        {
          url: require('../../assets/images/company_02.png'),
        },
        {
          url: require('../../assets/images/company_03.png'),
        },
      ],
      swiperList3: [
        {
          url: require('../../assets/images/company_gsry1.png'),
        },
        {
          url: require('../../assets/images/company_gsry2.png'),
        },
        {
          url: require('../../assets/images/company_gsry3.png'),
        },
      ],
      tableData: [
        {
          name: '自主研发SAAS前端开发',
          type: '技术',
          date: '2022-10-20',
          address: '武汉',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: 'Java开发工程师',
          type: '技术',
          date: '2022-10-20',
          address: '武汉/威海   ',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '医药售前讲师',
          type: '技术',
          date: '2022-10-20',
          address: '武汉',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '售前物流规划',
          type: '技术',
          date: '2022-10-20',
          address: '威海',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '电器工程师',
          type: '产品',
          date: '2022-10-20',
          address: '威海',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '项目实施',
          type: '技术',
          date: '2022-10-20',
          address: '威海',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '运营专员',
          type: '技术',
          date: '2022-10-20',
          address: '武汉',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '营销部商务助理',
          type: '技术',
          date: '2022-10-20',
          address: '武汉',
          tel: 'xieyaling@csbr.cn',
        },
        {
          name: '销售经理/总监',
          type: '技术',
          date: '2022-10-20',
          address: '武汉',
          tel: 'xieyaling@csbr.cn',
        },
      ],
    };
  },
  created() {},
  methods: {
    onTabChange(item, index) {},
    handleSizeChange(val) {
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      this.pageIndex = val;
    },
  },
};
</script>
<style lang="less" scoped>
.home {
  //头部
  .home-header {
    position: relative;
    width: 100%;
    .bg-text {
      width: 1200px;
      // height: 200px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: absolute;
      top: 42%;
      left: 50%;
      transform: translate(-50%, -50%);
      color: #fff;
      .bg-text-left {
        text-align: left;
        .font-title {
          font-size: 40px;
          color: #3d3d3d;
        }
        .font-title-txt {
          font-size: 18px;
          font-weight: 400;
          line-height: 42px;
          color: #3d3d3d;
        }
        .next-btn {
          width: 140px;
          height: 40px;
          line-height: 40px;
          font-size: 18px;
          background: #4fa1a4;
          opacity: 1;
          border-radius: 24px;
          text-align: center;
          cursor: pointer;
        }
      }
      .bg-text-right {
        font-family: Source Han Sans CN;
        text-align: right;
        .font-one {
          font-size: 30px;
        }
        .font-two {
          font-size: 22px;
          line-height: 22px;
        }
      }
    }
  }
  //中间
  .main-contain {
    width: 1200px;
  }
  //公司介绍
  .company-intro {
    display: flex;
    justify-content: space-between;
    text-align: left;
    img {
      width: 640px;
    }
    .company-intro-box {
      padding-left: 62px;
      .company-title {
        font-size: 32px;
        margin-bottom: 48px;
      }
      .company-intro-txt {
        font-size: 16px;
        text-indent: 2em;
        line-height: 36px;
      }
      .intro-num-box {
        display: flex;
        justify-content: space-between;
        margin-top: 46px;
        .intro-num-list {
          .intro-num-title {
            font-size: 56px;
            color: #4fa1a4;
            p {
              font-size: 16px;
            }
          }
        }
      }
    }
  }
  //知识产权
  .zscq-swiper {
      position: relative;
    background: #fff;
    .swiper-button-prev {
      width: 48px;
      height: 48px;
      left: 0;
      background-size: 100% 100%;
      background-image: url('../../assets/images/swiper_left.png');
    }
    .swiper-button-next {
      width: 48px;
      height: 48px;
      right: 0;
      background-size: 100% 100%;
      background-image: url('../../assets/images/swiper_right.png');
    }
    .swiper-slide {
      background: #f5f5f5;
      img {
        width: 100%;
        padding: 20px 50px;
        box-sizing: border-box;
      }
    }
  }
  //安全守护
  .aqsh-box {
    display: flex;
    justify-content: space-between;
    width: 821px;
    margin: auto;
    .aqsh-box-list {
      width: 265px;
      background: #f5f5f5;
      img {
        width: 100%;
        padding: 50px 20px;
        box-sizing: border-box;
      }
    }
  }
  //公司照片
  .pic-swiper {
    position: relative;
    .swiper-button-prev {
      width: 68px;
      height: 68px;
      left: 0;
      background-size: 100% 100%;
      background-image: url('../../assets/images/swiper_left.png');
    }
    .swiper-button-next {
      width: 68px;
      height: 68px;
      right: 0;
      background-size: 100% 100%;
      background-image: url('../../assets/images/swiper_right.png');
    }
    img {
      width: 100%;
    }
  }
  //公司文化
  .culture-box {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    img {
      width: calc(50% - 10px);
      margin-bottom: 20px;
    }
  }
}
</style>
