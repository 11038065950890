<template>
  <div class="mobile-img-box" :style="{height:boxHeight,background:backColor, padding:paddingBox,border:border,marginTop:marginTopBox,marginBottom:marginBottomBox,margin:marginBox}">
    <img @click="getImg(url)" :style="{width:imgWidth,height:imgHeight}" :src="url" alt="">
  </div>
</template>
<script>
// import { ImagePreview } from "vant";
export default {
  components:{
    // ImagePreview
  },
  props:{
    url:{
      type:String,
      default:''
    },
    paddingBox:{
      type:String,
      default:''
    },
    border:{
      type:String,
      default:''
    },
    marginBox:{
      type:String,
      default:''
    },
    marginTopBox:{
      type:String,
      default:''
    },
    marginBottomBox:{
      type:String,
      default:''
    },
    boxHeight:{
      type:String,
      default:''
    },
    backColor:{
      type:String,
      default:'#fff'
    },
    imgWidth:{
      type:String,
      default:''
    },
    imgHeight:{
      type:String,
      default:''
    },
  },
  data(){
    return{
      
    }
  },
  methods: {
    getImg(i) {
      this.$ImagePreview([i]);
    },
  },
};
</script>
<style lang="less" scoped>
::-webkit-scrollbar {
  width: .05rem;
  height: .05rem;
}
// ::-webkit-scrollbar-thumb { //滑块部分
//   border-radius: .5rem;
//   background-color: #000;
// }
// ::-webkit-scrollbar-track { //轨道部分
//   box-shadow: inset 0 0 5px rgba(0,0,0,0.1);
//   background: #ededed;
//   border-radius: 5px;

// }
.mobile-img-box{
//   width: 100%;
  overflow-y: hidden;
  overflow-x: auto;
  box-sizing: border-box;
  img{
    height: 100%;
  }
}

</style>
